<template>
  <div class="index">
    <div class="index_box">
      <!-- 头部开始 -->
      <div class="header">
        <div class="flex align-center justify-between hp100 plr13 bbox">
          <div class="flex align-center">
            <img src="../assets/images/logo.png"
                 alt=""
                 width="66"
                 height="19" />
            <lang-select class="ml15"></lang-select>
          </div>
          <div class="flex align-center">
            <!-- 搜索 -->
            <img src="../assets/images/search.png"
                 alt=""
                 width="18"
                 height="19"
                 @click="jump('/search')" />
            <!-- 头像 -->
            <img :src="user.logo"
                 :onerror="errorImg"
                 alt=""
                 width="20"
                 height="20"
                 class="ml20 radius50"
                 @click="jump('/my')" />
            <!-- 更多菜单 -->
            <!-- <el-dropdown @command="handleCommand" trigger="click">
              <span class="el-dropdown-link">
                <img
                  src="../assets/images/more.png"
                  alt=""
                  width="20"
                  height="16"
                  class="ml20 mt5"
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, i) in machineTypeList"
                  :key="item.typeCode"
                  :command="i"
                  >{{ item.typeName }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 轮播图开始 -->
      <div class="banner wp100">
        <van-swipe :autoplay="5000"
                   :show-indicators="false"
                   style="height: 188px">
          <van-swipe-item v-for="item in bannerImg"
                          :key="item.id"
                          @click="toBannerDeatil(item.title, item.noticeCode, item.examCode)">
            <img v-lazy="item.mobileUrl"
                 class="wp100 hp100" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 轮播图结束 -->
      <!-- 公告开始 -->
      <div class="notice f14 flex align-center bbox plr13">
        <img src="../assets/images/notice.png"
             alt=""
             width="15"
             height="16" />
        <span class="text666 pl5">{{ $t("notice") }}：</span>
        <el-carousel height="44px"
                     arrow="never"
                     indicator-position="none"
                     direction="vertical"
                     class="flex1">
          <el-carousel-item v-for="item in bannerImg"
                            :key="item.id">
            <a target="_blank"
               @click="
                toBannerDeatil(item.title, item.noticeCode, item.examCode)
              "
               class="dblock noticeItem text666 text-cut">
              {{ item.title }}
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 公告结束 -->
      <!-- 一级二级类目开始 -->
      <div class="positionr"
           v-if="machineTypeList.length>4">
        <div class="f12 text999 textr pr10 positiona showArrow">
          {{$t("slideTip")}}
          <i class="el-icon-d-arrow-right"></i>
        </div>
      </div>
      <div class="classBox pb10">
        <div :class="machineTypeList.length>4?'flex align-start bbox pt10':'flex align-start bbox'">
          <dl class="wp25 bbox textc"
              v-for="item in machineTypeList"
              :key="item.typeCode">
            <dt class="textblue fw700 f15 pt5 pb5 text-cut">
              {{ item.typeName }}
            </dt>
            <dd class="text-cut"
                v-for="item2 in item.children.slice(0, 4)"
                :key="item2.typeCode"
                @click.stop="gotoDetails(item2.typeCode, item2.typeName)">
              {{ item2.typeName }}
            </dd>
            <div v-if="item.listShow">
              <dd class="text-cut"
                  v-for="item3 in item.children.slice(
                  4,
                  item.children.length
                )"
                  :key="item3.typeCode"
                  @click.stop="gotoDetails(item3.typeCode, item3.typeName)">
                {{ item3.typeName }}
              </dd>
            </div>

            <div class="text999 f12"
                 v-if="item.children.length > 4 && item.listShow == false"
                 @click="item.listShow = true">
              <i class="el-icon-arrow-down"></i>
              展开
            </div>
            <div class="text999 f12"
                 v-if="item.children.length > 4 && item.listShow == true"
                 @click="item.listShow = false">
              <i class="el-icon-arrow-up"></i>
              收起
            </div>
          </dl>
        </div>
      </div>
      <!-- 一级二级类目结束 -->
      <!-- 菜单开始 -->
      <div class="menuList flex flex-wrap bbox plr13 mt25">
        <div class="menuItem"
             @click="jump('/science')">
          <img src="../assets/images/menu_11.png"
               alt="" />
          <div class="text666 mt15 textc f12">
            {{ $t("iconMenu.academic") }}
          </div>
        </div>
        <div class="menuItem"
             @click="jump('/instrumentData', '故障手册')">
          <img src="../assets/images/menu_22.png"
               alt="" />
          <div class="text666 mt15 textc f12">{{ $t("iconMenu.fault") }}</div>
        </div>
        <div class="menuItem"
             @click="jump('/instrumentData', '新信息发布')">
          <img src="../assets/images/menu_33.png"
               alt="" />
          <div class="text666 mt15 textc f12">
            {{ $t("iconMenu.maintain") }}
          </div>
        </div>
        <div class="menuItem"
             @click="jump('/examType')">
          <img src="../assets/images/menu_55.png"
               alt="" />
          <div class="text666 mt15 textc f12">{{ $t("iconMenu.exam") }}</div>
        </div>
        <div class="menuItem"
             @click="jump('/tools')">
          <img src="../assets/images/menu_44.png"
               alt="" />
          <div class="text666 mt15 textc f12">{{ $t("iconMenu.tool") }}</div>
        </div>
        <div class="menuItem"
             @click="jump('/instrumentData', '资料下载')">
          <img src="../assets/images/menu_77.png"
               alt="" />
          <div class="text666 mt15 textc f12">{{ $t("iconMenu.upload") }}</div>
        </div>
        <!-- <div class="menuItem"
             v-if="user.type == '2'"
             @click="jump('/instrumentData', '社内资料')">
          <img src="../assets/images/menu_66.png"
               alt="" />
          <div class="text666 mt15 textc f12">{{ $t("iconMenu.datum") }}</div>
        </div> -->
      </div>
      <!-- 菜单结束 -->
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import LangSelect from "../components/LangSelect.vue";
import Footer from "../components/footer.vue";
import { Toast } from "vant";
export default {
  components: { LangSelect, Footer },
  data () {
    return {
      machineTypeList: [],
      isMore: "0",
      isMsg: "0",
      bannerImg: [], //banner
      noticeList: [], //公告
      searchValue: "",
      options: [], //下拉菜单
      imgUrl: "",
      errorImg: 'this.src="' + require("../assets/images/avatar.png") + '"',
      user: {
        logo:
          "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
        name: "请先登录",
        type: "",
      },
      classList: [
        {
          id: 1,
          parentNode: "ADAMS",
          children: [
            "GA-1172",
            "AX-4030",
            "HA-8180",
            "GA-1173",
            "AX-40303",
            "HA-81803",
          ],
          listShow: false,
        },
        {
          id: 2,
          parentNode: "AUTION",
          children: ["GA-1172", "AX-4030"],
          listShow: false,
        },
        {
          id: 3,
          parentNode: "POCT",
          children: [
            "GA-1172",
            "AX-4030",
            "HA-8180",
            "Ae-4020",
            "PA-4140",
            "AE-1252",
          ],
          listShow: false,
        },
        {
          id: 4,
          parentNode: "ADAMS",
          children: ["GA-1172", "AX-4030", "HA-8180"],
          listShow: false,
        },
        {
          id: 5,
          parentNode: "ADAMS",
          children: ["GA-1172", "AX-4030", "HA-8180", "Ae-4020", "PA-4140"],
          listShow: false,
        },
      ],
    };
  },
  created () {
    /**
     * 加载会员数据
     */
    var member = JSON.parse(localStorage.getItem("member"));
    var memberCode = "";
    if (null != member) {
      if (typeof undefined === member.isLogin || null == member.isLogin)
        member.isLogin = false;
      if (member.isLogin) {
        this.user.name = member.name;
        this.user.type = member.type;
        this.isLogin = true;
        if (
          !(
            undefined == member.logo ||
            null == member.logo ||
            "" == member.logo
          )
        )
          this.user.logo = member.logo;
      }
      memberCode = member.memberCode;
    }
    /**
     * 加载机型信息
     */
    this.loadMachineTypeList(memberCode);
    /**
     * 加载轮播图信息
     */
    this.loadBannerList(memberCode);
  },
  mounted () {
    // document.getElementById("loading").style.display = "none";
  },
  methods: {
    handleIndex () { },
    // 跳转页面
    jump (path, key) {
      var pushData = {};
      pushData.path = path;
      pushData.query = {};
      pushData.query.from = key;
      pushData.query.key = key;
      this.$router.push(pushData);
    },
    handleCommand (command) {
      this.$router.push({ path: "/instrumentData", query: { i: command } });
    },
    loadMachineTypeList (memberCode) {
      var that = this;
      var sendData = {};
      sendData.memberCode = memberCode;
      this.$http
        .post("machineType/queryHomePageMachineTypeList", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            var list = res.data.data.returnData;
            if (list.length > 0) {
              for (var i = 0; i < list.length; i++) {
                list[i].listShow = false;
              }
            }
            that.machineTypeList = list;
          }
        });
    },
    loadBannerList (memberCode) {
      var that = this;
      var sendData = {};
      sendData.memberCode = memberCode;
      this.$http.post("notice/queryBannberList", sendData).then(function (res) {
        if (200 == res.data.code) {
          that.bannerImg = res.data.data;
        }
      });
    },
    toBannerDeatil (title, code, examCode) {
      this.$router.push({
        path: "/noticeDetail",
        query: {
          title: title,
          noticeCode: code,
          examCode: examCode,
        },
      });
    },
    gotoDetails (typeCode, typeName) {
      var sendData = {};
      sendData.typeCode = typeCode;
      sendData.memberCode = localStorage.getItem("memberCode");
      var that = this;
      this.$http
        .post("memberMachineType/queryMachineStatus", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            if ("1" == res.data.data) {
              that.$router.push({
                path: "/informationDetail",
                query: {
                  typeCode: typeCode,
                  typeName: typeName,
                  key: that.key,
                },
              });
            } else {
              Toast("查询资料失败，请先通过当前机型的考试");
            }
          }
        });
    },
  },
};
</script>

<style  scoped>
.index {
  background-color: #fff;
}
.index_box {
  min-height: calc(100vh - 70px);
}
.header {
  height: 50px;
}
/* 导航栏 */
/* .navPop {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  width: 120px;
  text-align: center;
  position: fixed;
  top: 50px;
  right: 0;
}
.navPop li {
  padding: 10px;
  font-size: 12px;
} */
/* 公告 */
.notice {
  border-bottom: 1px solid #ededed;
}
.noticeItem {
  line-height: 44px;
}
/* 图标菜单 */
.menuList {
  padding-bottom: 10px;
}
.menuList .menuItem {
  width: calc((100% - 30px) / 4);
  width: -webkit-calc((100% - 30px) / 4);
  width: -moz-calc((100% - 30px) / 4);
  margin-right: 10px;
  margin-bottom: 14px;
  cursor: pointer;
}
.menuList .menuItem:nth-child(4n) {
  margin-right: 0;
}
.menuList .menuItem {
  box-sizing: border-box;
  text-align: center;
}
.menuList .menuItem img {
  width: 50px;
  height: 50px;
}
.classBox {
  width: 100%;
  overflow-x: auto;
  border-bottom: 1px solid #ededed;
}
.classBox dl {
  flex-shrink: 0;
  padding: 0 10px;
}
.classBox dd {
  background-color: #e5e5e5;
  color: #999999;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 13px;
  margin-bottom: 8px;
}
.showArrow {
  right: 0;
  top: -2px;
}
</style>
